//
//
//
//
//
//
//

import GlobalMixin from '../../../shared/GlobalMixins.js'
import gamePlayMixin from '../../../shared/GamePlayMixin.js'

export default {
  name: "Timer",
  mixins: [GlobalMixin, gamePlayMixin],
  data: () => ({

  })
}
